import { ENDPOINT } from "../config/endpoint.jsx";
export function authUser(form) {
    return fetch(`${ENDPOINT}/login`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    })
      .then((res) => {
        return res.json();
       
      })
      .catch((error) => {
        console.error('Error:', error);
        
      });
  }