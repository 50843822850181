import { ENDPOINT } from "../config/endpoint.jsx";

export function uploadExcel(file, codigoColumn, cantidadColumn, cart) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("codigoColumn", codigoColumn);
  formData.append("cantidadColumn", cantidadColumn);
  formData.append("cart", JSON.stringify(cart));

  return fetch(`${ENDPOINT}/uploadExcel`, {
    method: "POST",
    body: formData,
  })
    .then((res) => {
      if (!res.ok) throw new Error("Respuesta no Ok");
      return res.json();
    })
    .then((res) => {
      
      return res;
    })
    .catch((error) => {
      console.error("Error en la solicitud:", error);
      throw error; // Re-lanza el error para que pueda ser manejado en el componente que llama a esta función
    });
}

export function cargaAllExcel() {
  return fetch(`${ENDPOINT}/listarExcel`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(res => {
    if(!res.ok) throw new Error("Respuesta no Ok");

    return res.json();
  }).then(res => {
    const marcas = res;
    return marcas;
  })

  
}

export function cargaAllExcelProveedor(id) {
  return fetch(`${ENDPOINT}/listarExcel/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(res => {
    if(!res.ok) throw new Error("Respuesta no Ok");

    return res.json();
  }).then(res => {
    const marcas = res;
    return marcas;
  })

  
}
