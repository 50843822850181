import React, { useState, useEffect, useContext } from "react";
import ProveedorContext from "../context/ProveedorContext";
import CartContext from "../context/CartContext";
import { cargaProveedores, cargaProductos } from "../services/proveedores.jsx";
import BuscadorCbo from "./buscadorCbo.jsx";
import Swal from "sweetalert2";

export default function Buscador({actualizar, setactualizar, actualizarProveedor, setActualizarProveedor}) {
  const [cbo, setCbo] = useState([]);
  const [productos, setProductos] = useState([]);
  const { proveedorContext } = useContext(ProveedorContext);
  const { cart } = useContext(CartContext);
  const [productosCargados, setProductosCargados] = useState(false);
  const [idProveedor, setIdProveedor] = useState(0);


  useEffect(()=>{
    
      cargaProveedores()
      .then((proveedores) => {
        setCbo(proveedores);
        if(proveedorContext.length === 1){
          renderProductos(proveedorContext[0].id, true);
        }else{
        if(document.getElementById("cboProveedor").value != ""){
          renderProductos(document.getElementById("cboProveedor").value, false);
        }else{
          if (proveedores.length > 0) {
            renderProductos(proveedores[0].id, false);
          }
        }
      }
      })
      .catch((error) => console.error(error));
      setActualizarProveedor(false)
    
  },[actualizarProveedor])


  const renderProductos = (id, cargaAntigua) => {
    setProductosCargados(false);
    cargaProductos(id)
      .then((product) => {
        if(cargaAntigua){
          cart.forEach((itemCarrito) => {
            const productoIndex = product.findIndex(
              (producto) => producto.id_producto === itemCarrito.id_producto
            );
            if (productoIndex !== -1) {
              product[productoIndex].cantidad = itemCarrito.cantidad;
            }
          });
          
          setProductos(product)
        }else{
          setProductos(product);
        }
        setProductosCargados(true);
      })
      .catch((error) => console.error(error)).finally(setIdProveedor(id));
  };


  useEffect(()=>{
    if (actualizar) {
      let id = document.getElementById("cboProveedor").value;
      renderProductos(id, true)
      setactualizar(false)
    }
  }, [actualizar])

  useEffect(() => {
    if (!productosCargados) {
      Swal.fire({
          title: "Cargando Productos",
          html: "<div class='spinner-border' role='status'></div>",
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
      });
  } else {
      Swal.close();
  }
  }, [productosCargados])
 

  return (
    <>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
          <label htmlFor="">Seleccione el Proveedor:</label>
          <select
            onChange={(e) => renderProductos(e.target.value)}
            className="form-select"
            id="cboProveedor"
          >
          {cbo.map((option, index) => (
            <option
              key={option.id}
              value={option.id}
              selected={(!proveedorContext[0] && index === 0) || (proveedorContext[0] && proveedorContext[0].id === option.id)}
            >
              {option.nombre}
            </option>
          ))}



          </select>
          </div>
          {!productosCargados ? (
            <p>Cargando productos<span className="loading-dots">...</span></p>
            ) : productos.length === 0 ? (
              <p className="noProductos">EL Proveedor No Contiene Productos</p>
              ) : (
                
                <BuscadorCbo data={productos} proveedor={idProveedor}/>
            
          )}
        </div>
      </div>
      
    </>
  );
}
