import { ENDPOINT } from "../config/endpoint.jsx";

export function cargaAllProductos() {
  return fetch(`${ENDPOINT}/productos`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(res => {
    if(!res.ok) throw new Error("Respuesta no Ok");

    return res.json();
  }).then(res => {
    const productos = res;
    return productos;
  })
}

export function createProducto(form) {
  // Crear un objeto FormData
  const formData = new FormData();
  formData.append('nombre', form.nombreProducto);
  formData.append('marca', form.marca);
  formData.append('codigo_barra', form.codigo_barra);
  formData.append('tipoProducto', form.tipoProducto);
  formData.append('foto', form.foto);
  formData.append('medida', form.medida);

  // Realizar la solicitud
  return fetch(`${ENDPOINT}/productos`, {
    method: 'POST',
    body: formData,
  })
    .then((res) => {
      return res.json();
     
    })
    .catch((error) => {
      console.error('Error:', error);
      
    });
}

export function cargarTipoProducto() {
  return fetch(`${ENDPOINT}/tipo_producto`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(res => {
    if(!res.ok) throw new Error("Respuesta no Ok");

    return res.json();
  }).then(res => {
    const tipoProducto = res;
    return tipoProducto;
  })
}