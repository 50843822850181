import React, { useContext } from "react";
import Context from "../context/CartContext";

const EnviarWhatsapp = ({ telefono }) => {
  const { cart } = useContext(Context);
  const handleSendMessage = () => {
    const message = generateWhatsAppMessage(cart);
    const whatsappLink = `https://wa.me/+56${telefono}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappLink, "_blank");
  };

  const generateWhatsAppMessage = (items) => {
    const message = items
      .map((item, index) => {
        return `*Producto ${index + 1}:* ${item.producto_nombre+ " "+ item.numeracion+" "+item.medidaOficial}, Cantidad: ${
          item.cantidad
        }`;
      })
      .join("\n");

    return `*¡Hola! Aquí esta Nuestro Pedido:*\n${message}`;
  };

  return (
    <div class="col-12 d-flex justify-content-center align-items-center divBoton" style={{height: "100%"}}>
  <div>
    <label htmlFor="">Numero del Proveedor Ingresado: <b>+56{telefono}</b></label>
    <button className="btn btn-success col-12 mt-3" onClick={handleSendMessage}>
      Enviar carrito por WhatsApp
    </button>
  </div>
</div>

  );
};

export default EnviarWhatsapp;
