import { ENDPOINT } from "../config/endpoint.jsx";
export function createProductoProveedor(form) {
  return fetch(`${ENDPOINT}/proveedor/producto`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({id_producto:form.producto,id_proveedor:form.proveedor}),
  }).then((res) => {
    return res.json();
  });
}
