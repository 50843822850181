import React, { useContext, useRef } from "react";
import "./Estilos.css";
import Context from "../context/CartContext";
import { cargaProveedorUnico } from "../services/proveedores.jsx";
import ContextProveedor from "../context/ProveedorContext";
import { useNavigate } from "react-router-dom";

export default function Sidebar() {
  const { cart } = useContext(Context);
  const { setProveedorContext } = useContext(ContextProveedor);
  const buttonRef = useRef(null);
  const cantidadTotal = cart.reduce((total, item) => total + item.cantidad, 0);
  const navigate = useNavigate();

  const handleClickMostrarCarro = async () => {
    if(cart.length !== 0){
      try {
        const id = document.getElementById("cboProveedor").value;
        const proveedor = await cargaProveedorUnico(id);
        setProveedorContext(proveedor);
        const backdrop = document.querySelector(".offcanvas-backdrop");
        if (backdrop && backdrop.classList.contains("show")) {
          backdrop.classList.remove("show");
        }
        document.body.style = "";
        document.body.removeAttribute("data-bs-overflow");
        document.body.removeAttribute("data-bs-padding-right");
        navigate("/confirmar");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const isCartEmpty = cart.length === 0;

  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header sidebar-header">
          <h5
            className="offcanvas-title sidebar-titulo"
            id="offcanvasRightLabel"
          >
            Carrito de Solicitud
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body sidebar-body">
          <div className="row">
            {cart.map((element) => (
              <div
                key={element.id}
                className="col-md-12 container-cart"
                style={{ border: "1px solid black" }}
              >
                <div className="row align-items-center">
                  <div className="col-md-3 container-img-cart">
                    <img
                      src={"https://storage.googleapis.com/imagenes-ponsrazas/" + element.imagen}
                      className="img-fluid col-md-12"
                      alt="..."
                      style={{ objectFit: "contain", height: "100px" }}
                    />
                  </div>
                  <div className="col-md-9">
                    <p>Nombre Producto: {element.producto_nombre +" "+element.numeracion+ " "+ element.medidaOficial}</p>
                    <p>Cantidad Solicitada: {element.cantidad}</p>
                    <p>Marca Producto: {element.marca_nombre}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="offcanvas-footer sidebar-footer">
          <p>Cantidad Total De Productos: {cantidadTotal}</p>
          <button
            className="btn btn-danger mt-2 col-md-12"
            onClick={handleClickMostrarCarro}
            ref={buttonRef}
            disabled={isCartEmpty}
          >
            Confirmar Carro
          </button>
        </div>
      </div>
    </>
  );
}
