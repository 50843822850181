import React from "react"
import { useState, useEffect } from "react"
import { FaTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2"
import Navbar from "./navbarVolver"
import "./Estilos.css"

export default function AgregarCheque() {
    const [numero, setNumero] = useState(0)
    const [fecha, setFecha] = useState("")
    const [monto, setMonto] = useState(0)
    const [actualizar, setActualizar] = useState(true);
    const [error, setError] = useState({
        numero: "hidden",
        fecha: "hidden",
        monto: "hidden"
    })
    const [datos, setDatos] = useState([]);


    useEffect(() => {
        if (actualizar) {
            fetch("https://cheques.bgajardo.com/Cheques")
                .then(response => response.json())
                .then(data => setDatos(data))
                .catch(error => console.error(error))
            setActualizar(false)
        }
    }, [actualizar])

    const cambiarFecha = (fecha) =>{
        let nuevaFecha = fecha.split("-")
        return `${nuevaFecha[2]}-${nuevaFecha[1]}-${nuevaFecha[0]}`;
    }




    const numeroCheque = (e) => {
        const valor = e.target.value;
        if (valor.length === 4) {
            setError((prev) => ({ ...prev, ["numero"]: "hidden" }));
        } else {
            setError((prev) => ({ ...prev, ["numero"]: "error" }));
        }
        setNumero(valor)
    }

    const fechaCompra = (e) => {
        const valor = e.target.value;
        if (valor.length === 10) {
            setError((prev) => ({ ...prev, ["fecha"]: "hidden" }));
        } else {
            setError((prev) => ({ ...prev, ["fecha"]: "error" }));
        }
        setFecha(valor)
    }

    const montoCompra = (e) => {
        const valor = e.target.value;
        if (valor.length > 0) {
            setError((prev) => ({ ...prev, ["monto"]: "hidden" }));
        } else {
            setError((prev) => ({ ...prev, ["monto"]: "error" }));
        }
        setMonto(valor)
    }

    const handleButtonPagar = (id) => {
        fetch("https://cheques.bgajardo.com/Cheques", {
            method: "PATCH",
            body: JSON.stringify({ id }),
            headers: { "Content-Type": "application/json" }
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    Swal.fire({
                        toast: true,
                        title: "Se Ha Cambiado el Estado",
                        icon: "success",
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 1500,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener("mouseenter", Swal.stopTimer);
                            toast.addEventListener("mouseleave", Swal.resumeTimer);
                        },
                    });
                    setActualizar(true);
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: data.message,
                        icon: "error"
                      }); 
                }
            })
    }

    // POST CHEQUE
    const handleFormCheque = () => {
        if((numero == 0 || numero.length != 4 ) || monto == 0 || fecha.length == 0){
            Swal.fire({
                title: "Error!",
                text: "Te Faltan Campos Por Ingresar o Hay Errores",
                icon: "error"
              });
              return
        }
        const jsonBody = { "numero": numero, "fecha": fecha, "monto": monto, "estado": 1 }
        fetch("https://cheques.bgajardo.com/Cheques", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(jsonBody)
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    Swal.fire({
                        title: "Se Ha Creado el Registro!",
                        text: data.message,
                        icon: "success"
                      });
                    setActualizar(true);
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: data.message,
                        icon: "error"
                      });
                }
            })
            .catch((error) => console.error(error))
    }








    const eliminarCheque = (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
        });
        swalWithBootstrapButtons.fire({
            title: "Estas Seguro de Eliminar el Cheque?",
            text: "No podras Revertir si lo Eliminas!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, Deseo Eliminar!",
            cancelButtonText: "No, Cancelar!",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                fetch("https://cheques.bgajardo.com/Cheques", {
                    method: "DELETE",
                    body: JSON.stringify({ id }),
                    headers: { "Content-Type": "application/json" }
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success) {
                            swalWithBootstrapButtons.fire({
                                title: "Eliminado!",
                                text: "El Registro Ha Sido Eliminado!",
                                icon: "success"
                            });
                            setActualizar(true);
                        } else {
                            swalWithBootstrapButtons.fire({
                                title: "Error",
                                text: data.message,
                                icon: "error"
                            });
                        }
                    })




            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire({
                    title: "Cancelado",
                    text: "Se ha Cancelado la Eliminacion del Registro",
                    icon: "error"
                });
            }
        });













    }

    return (
        <>
        <Navbar/>
        <div className="container">
            <div className="container">
                <h1 className="text-center">Registro de cheques</h1>
            </div>

            <div className="row align-items-center">
                <div className="col-3">
                    <label htmlFor="numero" className="form-label m-2">Ingrese número de cheque</label>
                    <input type="number" className="form-control" name="numero" id="numero" value={numero} onChange={numeroCheque} />
                    <small className={error["numero"]}>Error Se Necesitan 4 Numeros</small>
                </div>
                <div className="col-3">
                    <label htmlFor="fecha" className="form-label m-2">Ingrese fecha de compra</label>
                    <input type="date" className="form-control" name="fecha" id="fecha" value={fecha} onChange={fechaCompra} />
                    <small className={error["fecha"]}>Formato no Validos</small>
                </div>
                <div className="col-3">
                    <label htmlFor="monto" className="form-label m-2">Ingrese monto de cheque</label>
                    <input type="number" className="form-control" name="monto" id="monto" value={monto} onChange={montoCompra} />
                    <small className={error["monto"]}>Formato no Validos</small>
                </div>
                <div className="col-3 d-flex align-items-center justify-content-center">
                    <button className="btn btn-primary" onClick={handleFormCheque} id="btnEnviar">Guardar Cheque</button>
                </div>
            </div>

            <div>
                <table className="table table-bordered mt-5">
                    <thead>
                        <tr>
                            <th>Numero de cheque</th>
                            <th>Fecha de compra</th>
                            <th>Monto de cheque</th>
                            <th>Estado</th>
                            <th>Accion</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datos.map((element, index) => (
                            <tr key={element.id}>
                                <td className="col-2">{element.numero}</td>
                                <td className="col-3">{cambiarFecha(element.fecha)}</td>
                                <td className="col-2">${element.monto}</td>
                                <td className="col-2">{element.estado == 0 ? "No Pagado" : "Pagado"}</td>
                                <td className="col-3"><button className={`btn ${element.estado == 0 ? "btn-success" : "btn-danger"}  w-50 h-50`} onClick={() => { handleButtonPagar(element.id) }} id="btnEnviar">{element.estado == 0 ? "Pagar" : "Cancelar"}</button><button style={{marginLeft: "10px"}} onClick={() => { eliminarCheque(element.id) }} className="btn btn-warning "><FaTrashAlt /></button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
        </>
    )
}