

export function formValidatorProveedor(name, value) {
  const diccionario = {
    nombreProveedor: {
      longitud: 60,
      type: /^[a-zA-Z0-9._@-\s,&]+$/,
      error: {
        ambos: `NOMBRE: Error en la Longitud Y Formato, Maximo 60 caracteres`,
        longitud: "NOMBRE: Error en la Longitud, Maximo 60 caracteres",
        formato: "NOMBRE: Error en el Formato",
      },
    },
    rutProveedor: {
      longitud: 12,
      type: /^\d{1,2}\.\d{3}\.\d{3}-[\dk]$/i,
      error: {
        ambos: `RUT: Error en la Longitud, Maximo 12 caracteres y Formato(XX.XXX.XXX-X)`,
        longitud: "RUT: Error en la Longitud, Maximo 12 caracteres",
        formato: "RUT: Error en el Formato(XX.XXX.XXX-X) ",
      },
    },
    telefonoProveedor: {
      longitud: 9,
      type: /^[0-9]+$/,
      error: {
        ambos: `TELEFONO: Error en la Longitud, Maximo 9 caracteres y Solo son Permitidos Numeros`,
        longitud: "TELEFONO:Error en la Longitud, Maximo 9 caracteres",
        formato: "TELEFONO:Error en el Formato, solo son Permitidos Numeros",
      },
    },
    correoProveedor: {
      longitud: 50,
      type: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      error: {
        ambos: `CORREO: Error en la Longitud, Maximo 50 caracteres o Formato`,
        longitud: "CORREO: Error en la Longitud, Maximo 50 caracteres",
        formato: "CORREO: Error en el Formato de email",
      },
    },
  };

  if (value.length > diccionario[name].longitud && !diccionario[name].type.test(value)) {
    return [name, diccionario[name].error.ambos];
  } else {
    if (value.length > diccionario[name].longitud) {
      return [name, diccionario[name].error.longitud];
    }
    if (!diccionario[name].type.test(value)) {
      return [name, diccionario[name].error.formato];
    }
  }
  return [name, null];
}

//buscar una forma de validar si la foto con nombre ya existe
export function formValidatorProducto(form){
  let errores = "";
  // if(form.codigo_barra.length === 0 || form.codigo_barra == "" || form.codigo_barra.length >= 100){
  //   errores += "Debes llenar el Codigo de Barra. <br>"
  // }

  if(form.nombreProducto.length === 0 || form.nombreProducto == "" || form.nombreProducto.length >= 150){
    errores += "Debes llenar el Nombre de Producto.<br>"
  }

  if(form.foto == null){
    errores += "Debes Agregar una Foto de Producto.<br>"
  }

  if(form.marca == 0){
    errores += "Debes Seleccionar una Marca de Producto.<br>"
  }

  if(form.tipoProducto == 0){
    errores += "Debes Seleccionar un Tipo de Producto.<br>"
  }

  if(form.medida == 0){
    errores += "Debes Seleccionar una Unidad de Medida.<br>"
  }

  return errores;
}


export function formValidatorPP(form){
  let errores = "";

  if(form.proveedor == 0){
    errores += "Debes Seleccionar un Proveedor.<br>"
  }

  if(form.producto == 0){
    errores += "Debes Seleccionar un Producto.<br>"
  }

  return errores;
}