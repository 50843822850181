import React, { useState, useContext } from "react";
import {useNavigate} from "react-router-dom"
import { authUser } from "../services/login";
import Context from "../context/UserContext"
import './Estilos.css'

export default function Login() {
  const {setIsLogged, setUsuario} = useContext(Context);
  const [error, setError] = useState("");
  const [boton, setBoton] = useState("Iniciar Sesion!");
  const [formulario, setFormulario] = useState({
    user: "",
    pass: "",
  });
  const navigate = useNavigate();
  
  const handleSubmitForm = () => {
    setBoton("Cargando...")
    authUser(formulario).then((res) => {
      if (res.success) {
        setUsuario(res.nombre)
        setIsLogged(true);
        setError("")
        navigate("/home");

      } else {
        setError(res.message)
      }
      setBoton("Iniciar Sesion!")
    });
  };

  const handleChangeForm = (event) => {
    const { name, value } = event.target;
    setFormulario((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  return (
    <>
    <div className="bodyLogin">
        <div className="loginContainer">
          <div className="row">
            <h1 className="tituloLogin col-12">Inicio de Sesion</h1>
            <form onSubmit={handleSubmitForm}>
            <div className="mt-3 col-12">
                <label for="user" className="form-label">Nombre de Usuario</label>
                <input type="text" className="form-control" name="user" id="user" onChange={handleChangeForm} value={formulario.user} aria-describedby="user"/>
            </div>
            <div className="mt-3 col-12">
                <label for="pass" className="form-label">Contraseña</label>
                <input type="password" className="form-control" name="pass" id="pass" onChange={handleChangeForm} value={formulario.pass}/>
            </div>
            
            <button type="button" className="btn btn-primary col-12 mt-3 mb-2" onClick={handleSubmitForm}>{boton}</button>
                {error && <p className="text-danger">{error}</p>}
            
            </form>
            </div>
        </div>
    </div>
    </>
  );
}
