import React, { useState, useContext, useEffect } from "react";
import Context from "../context/CartContext.jsx";
import EnviarWhatsapp from "./EnviarWhatsapp.jsx";
import ExportarExcelCargado from "./ExportarExcelCargado.jsx";
import EnviarExcelTabla from "./EnviarExcelTabla.jsx";
import ProveedorContext from "../context/ProveedorContext.jsx";
import NavbarVolver from "./navbarVolver.jsx";
export default function MostrarCarro() {
  const { cart, setCart } = useContext(Context);
  const { proveedorContext } = useContext(ProveedorContext);
  console.log(proveedorContext)
  useEffect(() => {
    const backdropElement = document.querySelector('.offcanvas-backdrop');
    if (backdropElement) {
      backdropElement.remove();
    }
  }, []);


  return (
    <>
    <NavbarVolver/>
      <h1 className="text-center titulo">Confirmacion de Pedido</h1>
      <div className="container">
      <div className="sombraTabla" style={{ maxHeight: "600px", overflowY: "auto" }}>
  <table className="table table-bordered" style={{margin:"0"}}>
    <thead style={{ position: "sticky", top: "0", zIndex: "1", background: "#fff" }}>
      <tr>
        <th>Imagen</th>
        <th>Nombre</th>
        <th>Marca</th>
        <th>Cantidad</th>
      </tr>
    </thead>
    <tbody style={{ overflowY: "auto", maxHeight: "500px" }}>
      {cart.map((producto) =>
        producto.cantidad === 0 ? null : (
          <tr key={producto.id_producto}>
            <td>
              <img
                src={"https://storage.googleapis.com/imagenes-ponsrazas/"+producto.imagen}
                style={{ maxHeight: "100px", objectFit: "contain" }}
                alt=""
              />
            </td>
            <td>{producto.producto_nombre + " "+ producto.numeracion+" "+producto.medidaOficial}</td>
            <td>{producto.marca_nombre}</td>
            <td>{producto.cantidad}</td>
          </tr>
        )
      )}
    </tbody>
  </table>
</div>

        <div className="row" style={{marginTop:"20px", marginBottom:"20px"}}>
          <div className="col-6 " >
            <EnviarWhatsapp telefono={proveedorContext[0].telefono} />
          </div>
          <div className="col-6">
            <EnviarExcelTabla proveedor={proveedorContext[0].nombre} idProveedor={proveedorContext[0].id}/>
          </div>
        </div>
      </div>
    </>
  );
}
