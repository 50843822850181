import React, { useEffect, useState } from "react";
import Navbar from "./navbarVolver";
import "./Estilos.css";
import { cargaAllExcel, cargaAllExcelProveedor } from "../services/excel.jsx";
import { cargaProveedoresExcel } from "../services/proveedores.jsx";
import { ENDPOINT } from "../config/endpoint.jsx";
import Swal from "sweetalert2";

export default function MostrarExcel() {
  const [datos, setDatos] = useState([]);
  const [datosCbo, setDatosCbo] = useState([]);
  const [selectedCbo, setSelectedCbo] = useState("XX");

  useEffect(() => {
    cargaAllExcel()
      .then((data) => {
        const sortedData = ordenarDatos(data);
        setDatos(sortedData);
      })
      .catch((error) => console.error(error));
    cargaProveedoresExcel()
      .then((data) => {
        const sinVacios = data.filter((e) => e.cantidad > 0);
        setDatosCbo(sinVacios);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (selectedCbo === "XX") {
      cargaAllExcel()
        .then((data) => {
          const sortedData = ordenarDatos(data);
          setDatos(sortedData);
        })
        .catch((error) => console.error(error));
      return;
    }
    cargaAllExcelProveedor(selectedCbo)
      .then((data) => {
        const sortedData = ordenarDatos(data);
        setDatos(sortedData);
      })
      .catch((error) => console.error(error));
  }, [selectedCbo]);

  const ordenarDatos = (data) => {
    return data.sort((a, b) => {
      const fechaA = new Date(a.fecha + 'T' + a.hora);
      const fechaB = new Date(b.fecha + 'T' + b.hora);
      return fechaB - fechaA; // Orden descendente
    });
  };

  const handleClickDelete = (id) => {
    Swal.fire({
      title: "Estas Seguro?",
      text: "Si lo eliminas no podrás recuperarlo!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminarlo!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(`${ENDPOINT}/eliminarExcel/${id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (!response.ok) {
            throw new Error(`Error al eliminar el archivo: ${response.statusText}`);
          }

          const result = await response.json();
          Swal.fire({
            title: "Operación Exitosa!",
            text: result.message,
            icon: "success",
          });

          setDatos((prevDatos) => prevDatos.filter((dato) => dato.id !== id));
        } catch (error) {
          console.error('Error:', error);
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
          });
        }
      }
    });
  };

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="col-12">
          <h1 className="text-center">Listado Planillas de Pedidos</h1>
        </div>
        <div className="col-4">
          <label htmlFor="proveedor">Seleccione un Proveedor:</label>
          <select
            name=""
            id="proveedor"
            className="col-12 form-control"
            value={selectedCbo}
            onChange={(e) => setSelectedCbo(e.target.value)}
          >
            <option value="XX">Todos</option>
            {datosCbo.map((e) => (
              <option key={e.id} value={e.id}>
                {e.nombre}
              </option>
            ))}
          </select>
        </div>

        <div>
          <table className="table table-bordered mt-3 col-12">
            <thead>
              <tr>
                <th className="col-6">Proveedor</th>
                <th className="col-2">Fecha</th>
                <th className="col-2">Hora</th>
                <th className="col-1">Descargar</th>
                <th className="col-1">Eliminar</th>
              </tr>
            </thead>
            <tbody>
              {datos.length === 0 ? (
                <tr>
                  <td colSpan={5}>No hay Datos</td>
                </tr>
              ) : (
                datos.map((e) => (
                  <tr key={e.id}>
                    <td>{e.nombre}</td>
                    <td>{e.fecha}</td>
                    <td>{e.hora}</td>
                    <td>
                      <a
                        href={`${ENDPOINT}/descargar/${e.nombreArchivo}`}
                        download
                      >
                        <img
                          className="mx-auto d-block img-table"
                          height={"50px"}
                          width={"50px"}
                          alt="Descargar Excel"
                          src="/excel.png"
                        />
                      </a>
                    </td>
                    <td>
                      <a href={"#"} onClick={() => handleClickDelete(e.id)}>
                        <img
                          className="mx-auto d-block img-table"
                          style={{ margin: "5px" }}
                          height={"40px"}
                          width={"40px"}
                          alt="Eliminar Excel"
                          src="/eliminar.png"
                        />
                      </a>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
