import { ENDPOINT } from "../config/endpoint.jsx";

export function cargaProveedores() {
  return fetch(`${ENDPOINT}/proveedor`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(res => {
    if(!res.ok) throw new Error("Respuesta no Ok");

    return res.json();
  }).then(res => {
    const proveedores = res;
    return proveedores;
  })
}

export function cargaProveedoresExcel() {
  return fetch(`${ENDPOINT}/proveedorexcel`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(res => {
    if(!res.ok) throw new Error("Respuesta no Ok");

    return res.json();
  }).then(res => {
    const proveedores = res;
    return proveedores;
  })
}

export function cargaProductos(idProveedor) {
  return fetch(`${ENDPOINT}/proveedor/${idProveedor}/productos`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(res => {
    if(!res.ok){
      return [];
    }

    return res.json();
  }).then(res => {
    const productos = res;
    return productos;
  })
}

export function cargaProveedorUnico(idProveedor) {
  return fetch(`${ENDPOINT}/proveedor/${idProveedor}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(res => {
    if(!res.ok){
      return [];
    }
    return res.json();
  }).then(res => {
    const productos = res;
    return productos;
  })
}


export function createProveedor(form) {
  // Realizar la solicitud
  return fetch(`${ENDPOINT}/proveedor`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(form),
  })
    .then((res) => {
      return res.json();
     
    })
    .catch((error) => {
      console.error('Error:', error);
      
    });
}