// AppRouter.jsx
import React, {useContext} from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./HomePage"
import MostrarCarro from "./mostrarCarro";
import AgregarCheque from "./AgregarCheque.jsx";
import MostrarExcel from "./mostrarExcel.jsx";
import Login from "./Login.jsx"
import Context from "../context/UserContext.jsx";

const AppRouter = () => {
  const { isLogged } = useContext(Context);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={isLogged? <HomePage /> : <Navigate to="/"/>} />
        <Route path="/confirmar" element={isLogged? <MostrarCarro /> : <Navigate to="/"/>} />
        <Route path="/cheques" element={isLogged? <AgregarCheque /> : <Navigate to="/"/>} />
        <Route path="/excel" element={isLogged? <MostrarExcel /> : <Navigate to="/"/>} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
