import { ENDPOINT } from "../config/endpoint.jsx";

export function cargaAllMarcas() {
    return fetch(`${ENDPOINT}/marcas`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(res => {
      if(!res.ok) throw new Error("Respuesta no Ok");
  
      return res.json();
    }).then(res => {
      const marcas = res;
      return marcas;
    })
  }

  