import React, {useState} from "react";


const Context = React.createContext([]);


export function UserContextProvider({children}){
    const [isLogged, setIsLogged] = useState(false);
    const [usuario, setUsuario] = useState(null);
    return <Context.Provider value={{isLogged, setIsLogged, usuario, setUsuario}}>
        {children}
    </Context.Provider>

}

export default Context;