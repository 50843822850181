import { useContext} from "react";
import {Link} from "react-router-dom"
import Context from "../context/UserContext";
export default function Navbar() {
  const { usuario } = useContext(Context);

  return (
    <>
    <nav className="navbar navbar-expand-lg border-bottom border-body navDesign">
      
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img
              src="logo-pons.png" 
              alt="Logo"
              height="45"
              className="d-inline-block align-text-top"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link to="/home" className="link-nav">Volver Al Inicio</Link>
              </li>
            </ul>
            <span className="navbar-text">Usuario: {usuario}</span>
          </div>
        </div>
      </nav>
    </>
  );
}
