import { useContext, useState, useEffect } from "react";
import { cargaProveedores, createProveedor } from "../services/proveedores.jsx";
import { cargaAllMedida } from "../services/medida.jsx";
import {
  cargaAllProductos,
  createProducto,
  cargarTipoProducto,
} from "../services/productos.jsx";
import { cargaAllMarcas } from "../services/marca.jsx";
import { createProductoProveedor } from "../services/productoProveedor.jsx";
import {
  formValidatorProveedor,
  formValidatorProducto,
  formValidatorPP,
} from "../helpers/validador.jsx";
import "./Estilos.css";
import Select from "react-select";
import Context from "../context/UserContext";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
export default function Navbar({ setActualizar, setActualizarProveedor }) {
  const navigate = useNavigate();
  const { usuario, setIsLogged } = useContext(Context);
  const [proveedores, setProveedores] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [tipoProducto, setTipoProducto] = useState([]);
  const [medidas, setMedidas] = useState([]);
  const [productos, setProductos] = useState([]);
  const [errores, setErrores] = useState([]);
  const [selectedOptionMarca, setSelectedOptionMarca] = useState([]);
  const [selectedOptionMedida, setSelectedOptionMedida] = useState([]);
  const [selectedOptionTp, setSelectedOptionTp] = useState([]);
  const determineInitialCartPosition = () => {
    let anchoDispositivo =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    return anchoDispositivo < 992;
  };

  const [posicionCart, setPosicionCart] = useState(
    determineInitialCartPosition
  );

  useEffect(() => {
    const handleResize = () => {
      setPosicionCart(determineInitialCartPosition());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [formulario, setFormulario] = useState({
    proveedor: "",
    producto: "",
  });
  const [formularioProducto, setFormularioProducto] = useState({
    codigo_barra: "",
    nombreProducto: "",
    marca: "",
    tipoProducto: "",
    foto: null,
    medida: ""
  });

  const [formularioProveedor, setFormularioProveedor] = useState({
    rutProveedor: "",
    nombreProveedor: "",
    correoProveedor: "",
    telefonoProveedor: "",
  });

  useEffect(() => {
    cargaProveedores()
      .then((proveedores) => {
        const options = proveedores.map(({ id, nombre }) => ({
          value: id,
          label: nombre,
        }));
        console.log(options);
        setProveedores(options);
      })
      .catch((error) => console.error(error));
    cargaAllProductos()
      .then((producto) => {
        const options = producto.map(({ id, nombre }) => ({
          value: id,
          label: nombre,
        }));
        setProductos(options);
      })
      .catch((error) => console.error(error));
    cargaAllMarcas()
      .then((marca) => {
        const options = marca.map(({ id, nombre }) => ({
          value: id,
          label: nombre,
        }));
        setMarcas(options);
      })
      .catch((error) => console.error(error));
    cargarTipoProducto()
      .then((tipoProducto) => {
        const options = tipoProducto.map(({ id, nombre }) => ({
          value: id,
          label: nombre,
        }));
        setTipoProducto(options);
      })
      .catch((error) => console.error(error));
      cargaAllMedida()
      .then((medida) => {
        const options = medida.map(({ id, numeracion, nombre }) => ({
          value: id,
          label: numeracion + " "+ nombre,
        }));
        setMedidas(options);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleBlurProveedor = (e) => {
    const { name, value } = e.target;
    const errorMessage = formValidatorProveedor(name, value);
    let copia = [...errores];
    let estado = false;
    for (let i = 0; i < copia.length; i++) {
      const element = copia[i];
      if (element[0] === errorMessage[0]) {
        estado = true;
        if (errorMessage[1] != null) {
          element[1] = errorMessage[1];
        } else {
          copia.splice(i, 1);
          i--;
        }
      }
    }
    if (!estado) {
      if (errorMessage[1] != null) {
        copia.push([errorMessage[0], errorMessage[1]]);
      }
    }
    setErrores(copia);
  };

  const handleSubmit = () => {
    const msjError = formValidatorPP(formulario);
    console.log(msjError);
    if (msjError.length === 0) {
      createProductoProveedor(formulario).then((res) => {
        if (res.success) {
          Swal.fire({
            title: "Felicitaciones",
            text: "Se Ha Creado la Relacion Producto Proveedor!",
            icon: "success",
            confirmButtonText: "Ok!",
            didClose: () => {
              setActualizar(true);
            },
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: res.message,
            icon: "error",
            confirmButtonText: "Ok!",
          });
        }
      });
    } else {
      Swal.fire({
        title: "Error!",
        html: msjError,
        icon: "error",
        confirmButtonText: "Ok!",
      });
    }
  };

  const handleSubmitFormProd = () => {
    const msjError = formValidatorProducto(formularioProducto);
    if (msjError.length === 0) {
      createProducto(formularioProducto).then((res) => {
        if (res.success) {
          cargaAllProductos()
            .then((producto) => {
              const options = producto.map(({ id, nombre }) => ({
                value: id,
                label: nombre,
              }));
              setProductos(options);

              Swal.fire({
                title: "Felicitaciones",
                text: "Se Ha Creado el Producto Con Exito!",
                icon: "success",
                confirmButtonText: "Ok!",
              }).then(() => {
                setFormularioProducto({
                  codigo_barra: "",
                  nombreProducto: "",
                  marca: "",
                  tipoProducto: "",
                  foto: null,
                });
                document.getElementById("nombreProducto").value = "";
                document.getElementById("codigo_barra").value = "";
                document.getElementById("foto").value = null;
                setSelectedOptionMarca([]);
                setSelectedOptionMedida([]);
                setSelectedOptionTp([]);
                closeAndResetModal("modalProducto");
                document.body.style = "";
                document.body.style.overflowY = "visible";
              });
            })
            .catch((error) => console.error(error));
        } else {
          Swal.fire({
            title: "Error!",
            text: res.message,
            icon: "error",
            confirmButtonText: "Ok!",
          });
        }
      });
    } else {
      Swal.fire({
        title: "Error!",
        html: msjError,
        icon: "error",
        confirmButtonText: "Ok!",
      });
    }
  };

  const handleSubmitFormProveedor = () => {
    createProveedor(formularioProveedor).then((res) => {
      if (res.success) {
        Swal.fire({
          title: "Felicitaciones",
          text: "Se Ha Creado el Proveedor Con Exito!",
          icon: "success",
          confirmButtonText: "Ok!",
        }).then(() => {
          cargaProveedores()
            .then((proveedores) => {
              const options = proveedores.map(({ id, nombre }) => ({
                value: id,
                label: nombre,
              }));
              console.log(options);
              setProveedores(options);
            })
            .catch((error) => console.error(error));
          setActualizarProveedor(true);
          setFormularioProveedor({
            rutProveedor: "",
            nombreProveedor: "",
            correoProveedor: "",
            telefonoProveedor: "",
          });
          document.getElementById("rutProveedor").value = "";
          document.getElementById("nombreProveedor").value = "";
          document.getElementById("correoProveedor").value = "";
          document.getElementById("telefonoProveedor").value = "";
          closeAndResetModal("modalProveedor");
          document.body.style = "";
          document.body.style.overflowY = "visible";
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: res.message,
          icon: "error",
          confirmButtonText: "Ok!",
        });
      }
    });
  };

  const handleSelectChange = (item, id) => {
    const { value } = item;
    setFormulario((prevDatos) => ({
      ...prevDatos,
      [id]: value,
    }));
  };

  const handleChange = (event) => {
    const { name, value, files } = event.target;

    setFormularioProducto((prevForm) => ({
      ...prevForm,
      [name]: name === "foto" ? files[0] : value,
    }));
  };

  const handleChangeProveedor = (event) => {
    const { name, value } = event.target;

    setFormularioProveedor((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleSelectChangeMarca = (selectedOption) => {
    setSelectedOptionMarca(selectedOption);
    setFormularioProducto((prevForm) => ({
      ...prevForm,
      marca: selectedOption.value,
    }));
  };

  const handleSelectChangeMedida = (selectedOption) => {
    setSelectedOptionMedida(selectedOption);
    setFormularioProducto((prevForm) => ({
      ...prevForm,
      medida: selectedOption.value,
    }));
  };

  const handleSelectChangeTP = (selectedOption) => {
    setSelectedOptionTp(selectedOption);
    setFormularioProducto((prevForm) => ({
      ...prevForm,
      tipoProducto: selectedOption.value,
    }));
  };

  const closeAndResetModal = (modalId) => {
    const modal = document.getElementById(modalId);
    modal.classList.remove("show");
    modal.setAttribute("aria-hidden", "true");
    modal.style.display = "none";
    document.body.classList.remove("modal-open");
    const backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) {
      backdrop.remove();
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg border-bottom border-body navDesign">
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          <img
            src="logo-pons.png"
            alt="Logo"
            height="45"
            className="d-inline-block align-text-top"
          />
        </a>
        {posicionCart ? (
          <div className="divSvgCart d-flex ms-auto">
            <a
              href="#"
              className="vinculoCart"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="svgCart"
                width="25"
                height="25"
                fill="white"
                class="bi bi-cart3"
                viewBox="0 0 16 16"
              >
                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l.84 4.479 9.144-.459L13.89 4zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
              </svg>
            </a>
          </div>
        ) : (
          ""
        )}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a
                className="link-nav "
                aria-current="page"
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#modalRolear"
              >
                Rolear P.P.
              </a>
            </li>
            <li className="nav-item">
              <a
                className="link-nav "
                aria-current="page"
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#modalProducto"
              >
                Crear Producto
              </a>
            </li>
            <li className="nav-item">
              <a
                className="link-nav "
                aria-current="page"
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#modalProveedor"
              >
                Crear Proveedor
              </a>
            </li>
            <li className="nav-item">
              <a
                className="link-nav "
                aria-current="page"
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/cheques");
                }}
              >
                Ver Cheques
              </a>
            </li>
            <li className="nav-item">
              <a
                className="link-nav "
                aria-current="page"
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/excel");
                }}
              >
                Ver Excel
              </a>
            </li>
            <li className="nav-item">
              <a
                className="link-nav "
                aria-current="page"
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  setIsLogged(false);
                }}
              >
                Cerrar Sesion
              </a>
            </li>
          </ul>
          {!posicionCart ? (
            <div className="divSvgCart d-flex ms-auto">
              <a
                href="#"
                className="vinculoCart"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="svgCart"
                  width="25"
                  height="25"
                  fill="white"
                  class="bi bi-cart3"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l.84 4.479 9.144-.459L13.89 4zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                </svg>
              </a>
            </div>
          ) : (
            ""
          )}
          <span className="navbar-text" style={{ marginRight: "10px" }}>
            Usuario: {usuario}
          </span>
        </div>
        </div>
      </nav>

      <div
        className="modal fade"
        id="modalRolear"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Agregar Productos a Proveedor
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="proveedor">Seleccione el Proveedor:</label>
                  <Select
                    id="proveedor"
                    options={proveedores}
                    className="col-md-12 basic-single"
                    classNamePrefix="select"
                    onChange={(item) => handleSelectChange(item, "proveedor")}
                  />
                </div>
                <div className="mt-4">
                  <label htmlFor="producto">Seleccione el Producto:</label>
                  <Select
                    id="producto"
                    options={productos}
                    className="col-md-12 basic-single"
                    classNamePrefix="select"
                    onChange={(item) => handleSelectChange(item, "producto")}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Cerrar
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSubmit}
              >
                Registrar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalProducto"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Crear Producto
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmitFormProd}>
                <div>
                  <label htmlFor="producto">Nombre del Producto</label>
                  <input
                    type="text"
                    className="form-control col-md-12"
                    name="nombreProducto"
                    id="nombreProducto"
                    onChange={handleChange}
                    value={formularioProducto.nombre}
                  />
                  <small style={{color:"red"}}>No Agregar Unidad de Medida en Este Campo</small>
                </div>
                <div className="mt-4">
                  <label htmlFor="unidadMedida">
                    Seleccione la Unidad de Medida
                  </label>
                  <Select
                    id="unidadMedida"
                    options={medidas}
                    className="col-md-12 basic-single"
                    classNamePrefix="select"
                    value={selectedOptionMedida}
                    onChange={(item) => handleSelectChangeMedida(item)}
                  />
                </div>
                <div className="mt-4">
                  <label htmlFor="codigo_barra">
                    Codigo de Barra del Producto
                  </label>
                  <input
                    type="text"
                    className="form-control col-md-12"
                    name="codigo_barra"
                    id="codigo_barra"
                    onChange={handleChange}
                    value={formularioProducto.codigo_barra}
                  />
                </div>
                <div className="mt-4">
                  <label htmlFor="marca">Seleccione la Marca:</label>
                  <Select
                    id="marca"
                    options={marcas}
                    className="col-md-12 basic-single"
                    classNamePrefix="select"
                    value={selectedOptionMarca}
                    onChange={(item) => handleSelectChangeMarca(item)}
                  />
                </div>
                <div className="mt-4">
                  <label htmlFor="tipoProducto">
                    Seleccione el Tipo de Producto:
                  </label>
                  <Select
                    id="tipoProducto"
                    options={tipoProducto}
                    className="col-md-12 basic-single"
                    classNamePrefix="select"
                    value={selectedOptionTp}
                    onChange={(item) => handleSelectChangeTP(item)}
                  />
                </div>
                <div className="mt-4">
                  <label htmlFor="foto">Seleccione la Foto:</label>
                  <input
                    className="col-md-12 form-control"
                    type="file"
                    name="foto"
                    id="foto"
                    accept="image/*"
                    onChange={handleChange}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Cerrar
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSubmitFormProd}
              >
                Registrar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalProveedor"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Crear Proveedor
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmitFormProveedor}>
                <div>
                  <label htmlFor="nombreProveedor">Nombre del Proveedor:</label>
                  <input
                    type="text"
                    className="form-control col-md-12"
                    name="nombreProveedor"
                    id="nombreProveedor"
                    onBlur={handleBlurProveedor}
                    onChange={handleChangeProveedor}
                    value={formularioProveedor.nombre}
                  />
                </div>
                <div>
                  <label htmlFor="rutProveedor">Rut del Proveedor:</label>
                  <input
                    type="text"
                    className="form-control col-md-12"
                    name="rutProveedor"
                    id="rutProveedor"
                    onBlur={handleBlurProveedor}
                    onChange={handleChangeProveedor}
                    value={formularioProveedor.rut}
                  />
                </div>
                <div>
                  <label htmlFor="telefonoProveedor">
                    Telefono del Proveedor:
                  </label>
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon3">
                        +56
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control col-md-12"
                      name="telefonoProveedor"
                      id="telefonoProveedor"
                      aria-describedby="basic-addon3"
                      onBlur={handleBlurProveedor}
                      onChange={handleChangeProveedor}
                      value={formularioProveedor.telefono}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="correoProveedor">Correo del Proveedor:</label>
                  <input
                    type="text"
                    className="form-control col-md-12"
                    name="correoProveedor"
                    id="correoProveedor"
                    onBlur={handleBlurProveedor}
                    onChange={handleChangeProveedor}
                    value={formularioProveedor.correo}
                  />
                </div>
              </form>
              {errores.length === 0 ? null : (
                <div className="erroresModal col-md-12 mt-2">
                  {errores.map((element, index) => (
                    <p
                      key={index}
                      className="col-md-12 text-danger mb-1 text-center fw-bold"
                    >
                      {element[1]}
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Cerrar
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSubmitFormProveedor}
              >
                Registrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
