import React, { useContext, useEffect, useState } from "react";
import Context from "../context/CartContext";
import { cargarAllLocales } from "../services/locales";
import { saveAs } from 'file-saver';
import { ENDPOINT } from "../config/endpoint.jsx";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
function EnviarExcelTabla({ proveedor, idProveedor }) {
  const navigate = useNavigate();
  const [locales, setLocales] = useState([]);
  const [selectedLocal, setSelectedLocal] = useState("");
  const [cargando, setCargando] = useState(false)
  useEffect(() => {
    cargarAllLocales()
      .then((data) => {
        setLocales(data);
        if (data.length > 0) {
          setSelectedLocal(data[0].id);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(()=>{
    if(cargando){
      Swal.fire({
        title: "Generando el Excel",
        html: "<div class='spinner-border' role='status'></div>",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
    });
    }else{
      Swal.close();
    }
  },[cargando])

  const [comentario, setComentario] = useState("");
  const { cart, setCart } = useContext(Context);
 

  const enviarDatos = async () => {
    try {
      const local = locales.find((element) => element.id == selectedLocal);
      const payload = {
        cart,
        comentario,
        direccion:local.direccion,
        proveedor,
        idProveedor
      };

      const response = await fetch(ENDPOINT+'/generar', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        setCargando(false)
        const blob = await response.blob();
        saveAs(blob, `Pedido_${proveedor}_${new Date().toLocaleDateString()}.xlsx`);
        Swal.fire({
          title: "Se Ha Generado el Excel!",
          text: "Se te ha descargado automaticamente y tambien estara en la seccion de inicio 'Ver Excel'",
          icon: "success",
          confirmButtonText: "Ok!",
          didClose: () => {
            setCart([]);
            navigate("/home");
          }
        });
      } else {
        console.error('Error al enviar los datos: ', response.statusText);
      }
    } catch (error) {
      console.error('Error al enviar los datos: ', error);
    }
  };

  return (
    <div className="col-12 divBoton">
      <label>Ingrese un Comentario Adicional (opcional): </label>
      <input
        className="form-control"
        type="text"
        value={comentario}
        onChange={(e) => setComentario(e.target.value)}
      />
      <label>Seleccione el Local:</label>
      <select
        className="form-control"
        value={selectedLocal}
        onChange={(e) => setSelectedLocal(e.target.value)}
      >
        {locales.map((element) => (
          <option key={element.id} value={element.id}>
            {element.direccion}
          </option>
        ))}
      </select>
      <button
        className="btn btn-success col-md-12 mt-2"
        onClick={() => {
          setCargando(true)
          enviarDatos()
        }}
      >
        Exportar a Excel
      </button>
    </div>
  );
}

export default EnviarExcelTabla;
