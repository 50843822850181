import React, { useState, useEffect, useContext } from "react";
import {
  cargaAllProductos,
  cargarTipoProducto,
} from "../services/productos.jsx";
import { cargaAllMedidaProveedor } from "../services/medida.jsx";
import Context from "../context/CartContext.jsx";
import "./Estilos.css";
export default function BuscadorCbo(props) {
  const { data, proveedor } = props;
  const { setCart } = useContext(Context);

  const [busqueda, setBusqueda] = useState("");
  const [productosFiltrados, setProductosFiltrados] = useState(data);
  const [chkBusqueda, setChkBusqueda] = useState("option1");
  const [ordenLetras, setOrdenLetras] = useState("XX");
  const [cboTipoProducto, setCboTipoProducto] = useState([]);
  const [tipoProducto, setTipoProducto] = useState(0);
  const [medidas, setMedidas] = useState([]);
  const [medidasSeleccionada, setMedidasSeleccionadas] = useState("XX");
  const [productos] = useState(data);

  useEffect(() => {
    cargarTipoProducto()
      .then((tipo_producto) => {
        setCboTipoProducto(tipo_producto);
      })
      .catch((error) => console.error(error));
      cargaAllMedidaProveedor(proveedor)
      .then((oMedidas) => {
        setMedidas(oMedidas);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    let productosFiltradosLocal = [...productos];
    
    if (tipoProducto != 0) {
      productosFiltradosLocal = productosFiltradosLocal.filter(
        (producto) => producto.tipo_producto == tipoProducto
      );
    }

    if(medidasSeleccionada != "XX"){
      productosFiltradosLocal = productosFiltradosLocal.filter(
        (producto) => producto.unidad_medida == medidasSeleccionada
      )
    }

    if (chkBusqueda == "option1") {
      productosFiltradosLocal = productosFiltradosLocal.filter((producto) =>
        producto.producto_nombre
          .toLowerCase()
          .startsWith(busqueda.toLowerCase())
      );
    } else {
      productosFiltradosLocal = productosFiltradosLocal.filter((producto) =>
        producto.producto_nombre.toLowerCase().includes(busqueda.toLowerCase())
      );
    }

    if (ordenLetras === "DESC") {
      productosFiltradosLocal.sort((a, b) =>
        a.producto_nombre.localeCompare(b.producto_nombre)
      );
    } else if (ordenLetras === "ASC") {
      productosFiltradosLocal.sort((a, b) =>
        b.producto_nombre.localeCompare(a.producto_nombre)
      );
    }

    setProductosFiltrados(productosFiltradosLocal);
  }, [chkBusqueda, busqueda, data, ordenLetras, tipoProducto, medidasSeleccionada]);

  const manejarCantidad = (productoId, operacion, nuevaCantidad) => {
    const nuevosProductos = [...productos];
    const productoIndex = nuevosProductos.findIndex(
      (producto) => producto.id_producto === productoId
    );

    if (productoIndex !== -1) {
      const producto = nuevosProductos[productoIndex];

      if (operacion === "incrementar") {
        producto.cantidad = (producto.cantidad || 0) + 1;
      } else if (operacion === "decrementar" && producto.cantidad > 0) {
        producto.cantidad -= 1;
      } else if (operacion === "editar") {
        const cantidadNumerica = parseInt(nuevaCantidad, 10);
        if (!isNaN(cantidadNumerica)) {
          producto.cantidad = Math.max(0, cantidadNumerica);
        }
      }
      const productosFiltrados2 = nuevosProductos.filter(
        (producto) => producto.cantidad !== 0
      );
      setCart(productosFiltrados2);
    }
  };

  const handleTipoProductoChange = (e) => {
    setTipoProducto(e.target.value);
  };

  const handleChkChange = (e) => {
    setChkBusqueda(e.target.value);
  };

  const handleOrdenChange = (e) => {
    const nuevoOrden = e.target.value;
    setOrdenLetras(nuevoOrden);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2">
          <label htmlFor="ordenar" className="col-md-12">
            Ordenar Por:
          </label>
          <select
            name=""
            id=""
            onChange={handleOrdenChange}
            className="col-md-12 form-control"
          >
            <option value="XX">Ordenar por Letras</option>
            <option value="DESC">A {"->"} Z</option>
            <option value="ASC">Z {"->"} A</option>
          </select>
        </div>
        <div className="col-md-2">
          <label htmlFor="ordenarMedida" className="col-md-12">
            Ordenar Por Medida:
          </label>
          <select
            name=""
            id="ordenarMedida"
            onChange={(e) => {setMedidasSeleccionadas(e.target.value)}}
            className="col-md-12 form-control"
          >
            <option value="XX">Seleccione un Filtro</option>
            {
              medidas.map((mediditas) => (
                <option key={mediditas.id} value={mediditas.id}>{mediditas.numeracion + " " + mediditas.nombre}</option>
              ))
            }
            
          </select>
        </div>
        <div className="col-md-4">
          <label htmlFor="buscadorNombre" className="col-md-12">
            Buscar Producto:
          </label>
          <input
            type="text"
            id="buscadorNombre"
            className="col-md-12 form-control"
            value={busqueda}
            onChange={(e) => setBusqueda(e.target.value)}
          />
        </div>
        <div className="col-md-2">
          <label htmlFor="" className="col-md-12">
            Forma de Busqueda:
          </label>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios1"
              value="option1"
              checked={chkBusqueda === "option1"}
              onChange={handleChkChange}
            />
            <label class="form-check-label" for="exampleRadios1">
              Busqueda Inicial
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios2"
              value="option2"
              checked={chkBusqueda === "option2"}
              onChange={handleChkChange}
            />
            <label class="form-check-label" for="exampleRadios2">
              Busqueda Incluida
            </label>
          </div>
        </div>
        <div className="col-md-2">
          <label htmlFor="ordenar" className="col-md-12">
            Mostrar:
          </label>
          <select
            name=""
            id=""
            onChange={handleTipoProductoChange}
            className="col-md-12 form-control"
          >
            <option value="0">Todos</option>
            {cboTipoProducto.map((element) => (
              <option key={element.id} value={element.id}>
                {element.nombre}
              </option>
            ))}
          </select>
        </div>
      </div>

      {productosFiltrados.length === 0 ? (
        <p>No Hay Productos</p>
      ) : (
        <div className="container">
          <div className="row">
            {productosFiltrados.map((element) => (
              <div
                className="card cardDesign"
                style={{ width: "18rem", margin: "10px" }}
                key={element.id_producto}
              >
                <img
                  src={
                    "https://storage.googleapis.com/imagenes-ponsrazas/" +
                    element.imagen
                  }
                  className="card-img-top"
                  alt="..."
                  width={"100%"}
                  style={{ height: "300px", objectFit: "contain" }}
                />
                <div
                  className="card-body"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <h5 className="card-title">{element.producto_nombre +" "+ element.numeracion+" "+element.medidaOficial}</h5>
                  <p className="card-text">{element.marca_nombre}</p>

                  <div className="row">
                    <button
                      className="btn btn-danger col rounded-pill fw-bold"
                      onClick={() =>
                        manejarCantidad(element.id_producto, "decrementar")
                      }
                    >
                      -
                    </button>

                    <div className="col-6 ">
                      <input
                        type="number"
                        className="form-control"
                        value={
                          element.cantidad !== undefined ? element.cantidad : ""
                        }
                        onChange={(e) =>
                          manejarCantidad(
                            element.id_producto,
                            "editar",
                            parseInt(e.target.value, 10) || 0
                          )
                        }
                      />
                    </div>

                    <button
                      className="btn btn-success col rounded-pill fw-bold"
                      onClick={() =>
                        manejarCantidad(element.id_producto, "incrementar")
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
