import React, {useState} from "react";


const Context = React.createContext([]);


export function ProveedorContextProvider({children}){
    const [proveedorContext, setProveedorContext] = useState({
        id:0,
        rut: "",
        nombre: "",
        telefono: "",
        correo: ""
    });
    return <Context.Provider value={{proveedorContext, setProveedorContext}}>
        {children}
    </Context.Provider>

}


export default Context;