import React, { useState } from "react";
import Navbar from "./navbar.jsx";
import Buscador from "./buscador.jsx";
import './Estilos.css'
import Sidebar from "./sidebar.jsx";

export default function HomePage() {
  const [actualizarProductos, setActualizarProductos] = useState(false);
  const [actualizarProveedor, setActualizarProveedor] = useState(false);



  return (
    <>
      <Navbar setActualizar={setActualizarProductos} setActualizarProveedor={setActualizarProveedor} />
      <h1 className="text-center titulo">Solicitud de Productos</h1>
      <Buscador actualizar={actualizarProductos}  setactualizar={setActualizarProductos} actualizarProveedor={actualizarProveedor} setActualizarProveedor={setActualizarProveedor}/>
      <Sidebar/>
    </>
  );
}
