import { CartContextProvider } from "./context/CartContext";
import { UserContextProvider } from "./context/UserContext";
import { ProveedorContextProvider } from "./context/ProveedorContext.jsx";

import AppRouter from "./components/AppRouter.jsx"

function App() {
  return (
    <>
      <UserContextProvider>
            <CartContextProvider>
            <ProveedorContextProvider>
        <AppRouter>
          
          </AppRouter>
            </ProveedorContextProvider>
            </CartContextProvider>
      </UserContextProvider>
    </>
  );
}

export default App;
